import React from 'react';
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import ImageContainer from '../../../../components/image-container';
import SectionContainerLayoutWithFilter from '../../../../components/section-container-layout-with-filter';

export default function PlanYouTrip() {
  return (
    <Layout>
      <SEO lang='en' title='Plan Your Trip' />
      <SectionContainerLayoutWithFilter isViewAll title='PLAN YOUR TRIP' baseLink='/press/plan-your-trip' isDivider>
        <Grid container>
          <Box position='relative' clone>
            <Grid item xs={12}>
              <ImageContainer filename='visa-2' altText='plan-your-trip' />
              <Box
                position='absolute'
                left='0'
                style={{
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(0 , 0 , 0 , 0.5)',
                }}>
                <Box p={2}>
                  <Typography variant='h3' style={{ fontWeight: 'bold' }}>
                    Visa Requirements
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Box p={2} height='100%'>
              <Typography variant='h6'>
                <b>
                  Depending on your passport, you may need to arrange a visa before you travel to the UAE, please check
                  visa requirements and make sure you have a valid visa if needed.
                </b>
                <br />
                <br />
                <b>Kindly check your visa requirements by clicking on this link:</b>
                <br />
                <Link href='https://www.visitdubai.com/en/plan-your-trip/visa-information' target='_blank'>
                  https://
                  <wbr />
                  www.visitdubai.com/
                  <wbr />
                  en/
                  <wbr />
                  plan-your-trip/
                  <wbr />
                  visa-information
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
}
